import React, { useState, useEffect } from "react";
import './Main.css';
import triton_logo from './images/triton_logo.jpg'
import { redirect, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

/**
 * This component provides the rendering and logic for the "main menu" view for the user
 * @component
 * @returns {React.HTMLAttributes}
 */
function Main() { 

    console.log("in main");
    const location = useLocation();
    console.log("location:", location);
    const email = location.state.email;
    const user_id = location.state.user_id
    console.log("email:",email);
    const navigate = useNavigate();
    const [rapidIds, setRapidIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [rapidIdsLoading, setRapidIdsLoading] = useState(true);
    const [appointmentsLoading, setAppointmentsLoading] = useState(true);
    const [upcomingAppointments, setUpcomingAppointments] = useState([]);
    console.log("rapidIDs:", rapidIds);
    console.log("upcomingApts:",upcomingAppointments);

    /**
     * This function refreshes the page whenever it is first loaded or one of the state variables is updated. Here it is used to 
     * get the RAPID IDs and Appointments associated with the user. 
     */
    useEffect(() => {
        console.log("in useeffect func");
        if(isLoading){
            const getRapidIdsPerUserEndpoint = String(process.env.REACT_APP_API_URI) + "/rapid_ids/" + user_id;
            axios.get(getRapidIdsPerUserEndpoint)
            .then((response) => {
                console.log(response);
                setRapidIds(response.data);
                setRapidIdsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setRapidIdsLoading(false);
            });
            
            const getAptsPerUserEndpoint = String(process.env.REACT_APP_API_URI) + "/user-appointments/" + user_id;
            axios.get(getAptsPerUserEndpoint)
            .then(response => {
                console.log(response)
                setUpcomingAppointments(response.data);
                setAppointmentsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setAppointmentsLoading(false);
            });
            if(!rapidIdsLoading && !appointmentsLoading){
                setIsLoading(false);
            }
    }});
    
    /**
     * Hook to handle when one of the RAPID ID table rows is selected, loads the correct patient view. 
     * @param {React.HTMLAttributes} id 
     * @returns {null}
     */
    const handleIDRowClick = (id) => () => {
        // Implement your logic for handling ID row click here
        console.log(`ID clicked: ${id}`);
        navigate("/patientview", {
            state: {
                email: email,
                rapid_id: id,
                user_id: user_id
            }
        })
    };

    /**
     * Hook to handle when one of the appointment table rows is selected, loads the correct appointment view. 
     * @param {React.HTMLAttributes} appointment 
     * @returns {null}
     */
    const handleAptRowClick = (appointment) => () => {
    // Implement your logic for handling appointment row click here
        console.log(`Appointment clicked: ${appointment.rapid_id_assigned_to} at ${appointment.scheduled_start}`);
        navigate("/aptview", {
            state: {
                email: email,
                selectedApt: appointment,
                user_id: user_id
            }
        })
    };

    /**
     * Hook to handle when the user selcts the logout button. Resets the state and returns them to the landing page. 
     */
    const handleLogoutClick = () => {
        console.log("Attempting to logout...")
        const logoutEndpoint = String(process.env.REACT_APP_API_URI) + "/logout/" + String(user_id);
        axios.delete(logoutEndpoint).then((response) => {
            console.log(response);
            navigate("/", {
                state: {

                }
            })
        }, (error) => {
            console.log(error);
        } 
        )
    }

    /**
     * Hook to handle keyboard selection of table row
     * @param {React.ChangeEvent} event 
     * @param {React.HTMLAttributes} id 
     */
    const handleIDRowKeyDown = (event, id) => {
        if (event.key === "Enter") {
            handleIDRowClick(id)();
        }
    };
    
    /**
     * Hook to handle keyboard selection of table row
     * @param {React.ChangeEvent} event 
     * @param {React.HTMLAttributes} id 
     */
    const handleAptRowKeyDown = (event, appointment) => {
        if (event.key === "Enter") {
            handleAptRowClick(appointment)();
        }
    };

    /**
     * Hook to handle when the add RAPID ID button is selected. Sends the user to the add RAPID ID page. 
     */
    const handleAddRapidBtnClick = () => {
        // Implement your logic for adding a new rapid ID here
        console.log("Add RAPID ID button clicked");
        navigate("/addnewid", {
            state: {
                email: email,
                user_id: user_id
            }
        })
    };

    /**
     * Hook to handle enter hover event for table rows
     * @param {React.MouseEvent} e 
     */
    const handleTableRowMouseEnter = (e) => {
        console.log("e:",e);
        e.target.parentElement.style.backgroundColor = "#f5f5f5";
    };

    /**
     * Hook to handle exit hover event for table rows
     * @param {React.MouseEvent} e 
     */
    const handleTableRowMouseLeave = (e) => {
        e.target.parentElement.style.backgroundColor = "white";
    };

    /**
     * Rendering logic for component
     */
    if (isLoading || rapidIds === null || upcomingAppointments === null) {
        console.log("loading...");
        return <p>Loading...</p>;
    }
    else{
        console.log("done loading");
        return (
            <div className="dashboard-container">
                <div className="header-container">
                <button className="add-rapid-btn" onClick={handleLogoutClick}>
                    Log Out
                </button>
                <span className="welcome-message">Welcome, {email}!</span>
                <button
                    className="add-rapid-btn"
                    onClick={handleAddRapidBtnClick}
                >
                    Add a new RAPID ID
                </button>
                </div>
                <div className="content-container">
                <div className="tables-container">
                    <div className="rapid-ids-container">
                    <h2>Assigned IDs</h2>
                    {rapidIds.length === 0 ? (
                        <p>No RAPID IDs found</p>
                    ) : (
                        <table className="rapid-ids-table">
                        <thead>
                            <tr>
                                <th>RAPID ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rapidIds.map((rapid_id) => (
                            <tr
                                key={rapid_id.rapid_id}
                                tabIndex="0"
                                role="button"
                                onClick={handleIDRowClick(rapid_id.rapid_id)}
                                onKeyDown={(event) => handleIDRowKeyDown(event, rapid_id.rapid_id)}
                                onMouseEnter={handleTableRowMouseEnter}
                                onMouseLeave={handleTableRowMouseLeave}
                            >
                                <td>{rapid_id.rapid_id}</td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    )}
                    </div>
                    <div className="appointments-container">
                    <h2>Upcoming Appointments</h2>
                    {upcomingAppointments.length === 0 ? (
                        <p>No Upcoming Appointments Found</p>
                    ) : (
                        <table className="appointments-table">
                        <thead>
                            <tr>
                                <th>Date & Time</th>
                                <th>RAPID ID</th>
                                <th>Appointment ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {upcomingAppointments.map((appointment) => (
                            <tr
                                key={appointment._id.$oid}
                                tabIndex="0"
                                role="button"
                                onClick={handleAptRowClick(appointment)}
                                onKeyDown={(event) => handleAptRowKeyDown(event, appointment)}
                                onMouseEnter={handleTableRowMouseEnter}
                                onMouseLeave={handleTableRowMouseLeave}
                            >
                                <td>{appointment.scheduled_start}</td>
                                <td>{appointment.rapid_id_assigned_to}</td>
                                <td>{appointment._id.$oid}</td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    )}
                    </div>
                </div>
                </div>
                <div className="footer-container">
                    <img src={triton_logo} alt="Triton Logo" />
                </div>
            </div>
        );
    }
}

export default Main;