import React, { useState } from "react";
import { Link } from 'react-router-dom';
import './Home.css';

const logoImage = require('./images/triton_logo.jpg');

/**
 * This component provides the rendering logic for the landing page of the application. 
 * @component
 * @returns {React.HTMLAttributes}
 */
const Home = () => {
  return (
    <div className="home">
      <header className="header">
        <div className="logo">
          <img src={logoImage} alt="Triton Logo" />
        </div>
      </header>
      <section className="hero">
        <div className="hero__content">
          <h1>PROMPT Medical</h1>
          <p>A modern solution for remotely monitoring routine injections.</p>
          <br/><br/>
          <Link className="link-styles" to="/signin">
            <button>
              Sign In
            </button>
          </Link>
          <span class="tab"></span>
          <Link className="link-styles" to="/signup">
            <button>
              Sign Up
            </button></Link>
        </div>
      </section>
      <br/><br/>
      <section className="benefits">
        <div className="benefits__cards">
          <div className="card">
            <svg xmlns="http://www.w3.org/2000/svg" height="128px" viewBox="0 0 24 24" width="128px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-7 5h5v5h-5z"/></svg>
            <h3>Remote Injection Appointment Management System</h3>
          </div>
          <div className="card">
            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 20 20" height="128" viewBox="0 0 20 20" width="128" fill="#000000"><g><rect fill="none" height="20" width="20" x="0"/></g><g><g><path d="M16.4,4H3.6C2.72,4,2,4.72,2,5.6V8h1.5V5.6h13V8H18V5.6C18,4.72,17.28,4,16.4,4z"/><path d="M16.5,14.4h-13V12H2v2.4C2,15.28,2.72,16,3.6,16h12.8c0.88,0,1.6-0.72,1.6-1.6V12h-1.5V14.4z"/><path d="M12.16,6.89c-0.26-0.48-1.05-0.48-1.32,0L8.5,11.18L7.66,9.64C7.53,9.4,7.27,9.25,7,9.25H2v1.5h4.55l1.29,2.36 c0.13,0.24,0.38,0.39,0.66,0.39s0.53-0.15,0.66-0.39l2.34-4.29l0.84,1.54c0.13,0.24,0.38,0.39,0.66,0.39h5v-1.5h-4.55L12.16,6.89z"/></g></g></svg>
            <h3>Securely Monitor & Control Patient Treatment in Real-Time</h3>
          </div>
          <div className="card">
            <svg xmlns="http://www.w3.org/2000/svg" height="128px" viewBox="0 0 24 24" width="128px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2H0v2h24v-2h-4zM4 6h16v10H4V6z"/></svg>
            <h3>Remotely Control RAPIDS Microneedle Device Without an In-Person Appointment</h3>
          </div>
        </div>
      </section>
      <section className="cta">
        <h3>Patients, download the app and take control of your health today!</h3>
        <br/>
        <div className="cta__buttons">
          <button>
            Download for Android
          </button>
        </div>
      </section>

    </div>
  );
};

export default Home;

