import React, { useState, useEffect } from 'react';
import triton_logo from './images/triton_logo.jpg';
import { useNavigate, useLocation  } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import { ResizableBox } from 'react-resizable';
import Countdown from 'react-countdown';
import {
    LineChart,
    ResponsiveContainer,
    Legend,
    Tooltip,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
} from "recharts";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import './AptView.css';

/**
 * This component provides the rendering and logic for the appointment view of the application.
 * @component
 * @returns {React.HTMLAttributes}
 */
const AptView = () => {
    const location = useLocation();
    console.log("location:", location);
    const email = location.state.email;
    console.log("email:",email);
    const selectedApt = location.state.selectedApt;
    console.log("selectedApt:", selectedApt);
    const user_id = location.state.user_id;
    const navigate = useNavigate();

    // Add state variables for each input
    const [pumpStatus, setPumpStatus] = useState("Connected"); // Initial pump status is off
    const [monitorStatus, setMonitorStatus] = useState("Disconnected"); // Initial monitor status is off
    const [infusionDose, setInfusionDose] = useState(0);
    const [infusionStartTime, setInfusionStartTime] = useState("2024-01-03 12:34:12"); // Initial infusion start time is null
    const [elapsedTime, setElapsedTime] = useState("00:00:20:123"); // Initial elapsed time is empty
    const [amtAdministered, setAmtAdministered] = useState(0); // Initial drug amount administered is 0
    const [amtAdministeredPct, setAmtAdministeredPct] = useState(0);
    const [drug, setDrug] = useState("Insulin");
    const [showDelPopup, setShowDelPopup] = useState(false);
    const [showEmergencyPopup, setShowEmergencyPopup] = useState(false);
    const [showStartPopup, setShowStartPopup] = useState(false);
    const [showStopPopup, setShowStopPopup] = useState(false);
    const [aptStatus, setAptStatus] = useState("Not Started");
    const [startCountdownActive, setStartCountdownActive] = useState(false);

    const exampleVitalSigns = [
        {
            timestamp: "2024-01-03 12:34:12",
            heartRate: 75,
            bloodSugar: 70,
            bloodPressureSystolic: 110,
            bloodPressureDiastolic: 75,
        },
        {
            timestamp: "2024-01-03 12:34:17",
            heartRate: 70,
            bloodSugar: 75,
            bloodPressureSystolic: 100,
            bloodPressureDiastolic: 60,
        },
        {
            timestamp: "2024-01-03 12:34:22",
            heartRate: 79,
            bloodSugar: 80,
            bloodPressureSystolic: 120,
            bloodPressureDiastolic: 80,
        },
        {
            timestamp: "2024-01-03 12:34:27",
            heartRate: 60,
            bloodSugar: 60,
            bloodPressureSystolic: 100,
            bloodPressureDiastolic: 65,
        },
        {
            timestamp: "2024-01-03 12:34:32",
            heartRate: 63,
            bloodSugar: 64,
            bloodPressureSystolic: 112,
            bloodPressureDiastolic: 73,
        },
    ];

    const [vitalSignsData, setVitalSignsData] = useState(exampleVitalSigns);

    /**
     * Hook to handle selection of the delete appointment button. Brings up the delete confirmation popup.
     */
    const handleAptCancel = () => {
        setShowDelPopup(true);
    }

    /**
     * Hook to handle selection of the cancel button in the delete popup. 
     */
    const handlePopupCancel = () => {
        setShowDelPopup(false);
    };

    /**
     * Hook to handle selection of the emergency button. Brings up the emergency confirmation popup. 
     */
    const handleEmergencyClick = () => {
        setShowEmergencyPopup(true);
    };

    /**
     * Hook to handle selection of the confirm deletion button in the delete popup. Calls the API to delete the appointment from the database. 
     */
    const handlePopupDelete = () => {
        setShowDelPopup(false);
        console.log("deleting appointment:", selectedApt);
        const deleteAptEndpoint = String(process.env.REACT_APP_API_URI) + "/appointment/" + selectedApt._id.$oid;
        axios.delete(deleteAptEndpoint).then((response) => {
            console.log(response);
            navigate(-1, {
                state:{
                    email: email,
                    user_id: user_id
                }
            })
        })
    };

    /**
     * Hook to handle selection of the cancel button in the emergency popup
     */
    const handleEmergencyPopupCancel = () => {
        setShowEmergencyPopup(false);
    };

    /**
     * Hook to handle confirmation of an emergency in the emergency popup
     */
    const handleEmergencyPopupConfirm = () => {
        setShowEmergencyPopup(false);
        console.log("Emergency workflow triggered");
        setAptStatus("Stopped");
        
    };


    /**
     * Hook to handle when the appointment start/stop button is clicked and update its text to match the correct state. 
     */
    const handleAptStartStop = () => {
        if(aptStatus === "In Progress") {
            console.log("stop appointment button clicked");
            setShowStopPopup(true);
            console.log("showStopPopup: ", showStopPopup);
        } else if (aptStatus === "Not Started"){
            console.log("start appointment button clicked");
            setShowStartPopup(true);
        }
    }

    /**
     * Hook to handle confirmation of appointment start in the start appointment popup. Triggers a 10 second countdown before changing application into startup state. 
     */
    const handleStartInjection = () => {
        console.log("injection process is starting in 10 seconds");
        setStartCountdownActive(true);
        setShowStartPopup(false);
    }

    /**
     * Hook to handle when the cancel button is selected in the infusion start confirmation popup.
     */
    const handleCancelInjectionStart = () => {
        console.log("cancelling injection start");
        setShowStartPopup(false);
    }

    /**
     * Hook to handle when the confirm stop button is selected in the stop confirmation popup
     */
    const handleStopInjection = () => {
        console.log("stopping injection");
        setAptStatus("Stopped");
        setShowStopPopup(false);
    }

    /**
     * Hook to handle when the  cancel button is selected on the start injection popup during the countdown
     */
    const handleCountdownCancel = () => {
        console.log("Cancelling injection start while in countdown");
        //document.getElementById("countDown").stop;
        setStartCountdownActive(false);
    }

    /**
     * Hook to handle when the close popup button is selected on the confirm stop appointment popup
     */
    const handleCancelStopInjection = () => {
        console.log("closing stop appointment popup");
        setShowStopPopup(false);
    }

    /**
     * Hook to handle when the countdown in the start appointment popup finishes and the injection starts
     */
    const injectionStart = () => {
        console.log("injection started");
        setAptStatus("In Progress");
        setStartCountdownActive(false);
    }

    /**
     * UseEffect function runs on page load and every time a state variable is updated, Used here to fetch updated to the vital signs. 
     */
    useEffect(() => {
        console.log('showStopPopup:', showStopPopup);
        // Fetch data from your API and update state
        fetch('/api/vital-signs')
        .then((response) => response.json())
        .then((data) => setVitalSignsData(exampleVitalSigns));
    }, []);

    /**
     * Rendering logic
     */
    return (
        <div className="menu-screen">
            <div className="header4">
                <div className="header-content">
                    <button className="back-home-button" 
                        disabled={aptStatus==="In Progress"}
                        onClick={() => 
                            navigate(-1)
                        }>
                        Back
                    </button>
                    <div className="ScreenTitle">RAPID Infusion Control</div>
                    <div className="logo-container">
                        <img src={triton_logo} alt="Triton Logo" class="logo"/>
                    </div>
                </div>
            </div>
            <main className="main">
                <div className="left-column">
                    <div className='leftColumnHeader'>
                        <h2>System Control</h2>
                        <button onClick={handleAptStartStop} alt="Start/Stop Appointment" disabled={aptStatus==="Stopped"}>
                            {aptStatus === "Not Started" ? (
                                    <p>Start Injection</p>
                                ) : (
                                    <p>Stop Injection</p>
                                )
                            }
                        </button>
                        <div className='delAptButton'>
                            <button  onClick={handleAptCancel} disabled={aptStatus!=="Not Started"} alt="Delete Appointment">
                                <svg xmlns="http://www.w3.org/2000/svg" alt="Delete Appointment" viewBox="0 0 24 24"  fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.12 10.47L12 12.59l-2.13-2.12-1.41 1.41L10.59 14l-2.12 2.12 1.41 1.41L12 15.41l2.12 2.12 1.41-1.41L13.41 14l2.12-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"/></svg>
                            </button>
                        </div>
                    </div>
                    <div className='InfoPanel'>
                        <table className="aptInfoTable">
                            <tbody>
                                <tr>
                                    <td>
                                        <label htmlFor="rapid-id">RAPID ID:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.rapid_id_assigned_to}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="aptStatus">Appointment Status:</label>
                                    </td>
                                    <td>
                                        <label>{aptStatus}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="scheduled-start-time">Scheduled Start Time:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.scheduled_start}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="drug">Drug:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.drug}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="pump-status">Pump Status:</label>
                                    </td>
                                    <td>
                                        {
                                            {
                                                'Disconnected': 
                                                    <div className="disconnectedStatusIndicator">
                                                        Disconnected
                                                    </div>,
                                                'Warning':
                                                    <div className="warningStatusIndicator">
                                                        Warning
                                                    </div>,
                                                'Connected':
                                                    <div className="connectedStatusIndicator">
                                                        Connected
                                                    </div>
                                            }[pumpStatus]   
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="monitor-status">Monitor Status:</label>
                                    </td>
                                    <td>
                                        {
                                            {
                                                'Disconnected': 
                                                    <div className="disconnectedStatusIndicator">
                                                        Disconnected
                                                    </div>,
                                                'Warning':
                                                    <div className="warningStatusIndicator">
                                                        Warning
                                                    </div>,
                                                'Connected':
                                                    <div className="connectedStatusIndicator">
                                                        Connected
                                                    </div>
                                            }[monitorStatus]   
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="infusionDose">Infusion Dose:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.infusion_dose} mg</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="stepCount">Step Count:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.step_count} </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="timeIncrement">Time Increment:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.time_increment} </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="infusion-start-time">Infusion Start Time: </label>
                                    </td>
                                    <td>
                                        <label>{infusionStartTime} </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="elapsed-time">Elapsed Time:</label>
                                    </td>
                                    <td>
                                        <label>{elapsedTime}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="amt-administered">Drug Amount Administered:</label>
                                    </td>
                                    <td>
                                        <div className="amtAdministeredDiv">
                                            <label>{amtAdministered} mg</label>
                                            <span class="tab" />
                                            <label htmlFor="amt-administered-pct">{amtAdministeredPct}%</label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="alert-box">
                        <h3>Alert Status</h3>
                        <textarea id="alert-status" readOnly rows="6" style={{width: '100%'}} />
                    </div>
                </div>
                <div className="right-column">
                    <h2>RAPID Feedback</h2>
                    <div className="tabs">
                        <Tabs className="Tabs">
                            <TabList>
                                <Tab>Data Plot</Tab>
                                <Tab>Data Readout</Tab>
                            </TabList>
                            <TabPanel>
                                <h3>Data Plot</h3>
                                <ResponsiveContainer width="100%" aspect={2}>
                                    <LineChart data={vitalSignsData} >
                                        <Legend verticalAlign='top' height={36}/>
                                        <CartesianGrid />
                                        <XAxis dataKey="timestamp" angle={90} height={200} textAnchor='start' interval={"preserveStartEnd"} />
                                        <YAxis></YAxis>
                                        <Tooltip />
                                        <Line
                                            name="Heart Rate"
                                            dataKey="heartRate"
                                            stroke="black"
                                            activeDot={{ r: 8 }}
                                        />
                                        <Line 
                                            name="Blood Sugar"
                                            dataKey="bloodSugar" 
                                            stroke="red" 
                                            activeDot={{ r: 8 }} 
                                        />
                                        <Line 
                                            name="Systolic Blood Pressure"
                                            dataKey="bloodPressureSystolic" 
                                            stroke="blue" 
                                            activeDot={{ r: 8 }} 
                                        />
                                        <Line 
                                            name="Diastolic Blood Pressure"
                                            dataKey="bloodPressureDiastolic" 
                                            stroke="green" 
                                            activeDot={{ r: 8 }} 
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </TabPanel>
                            <TabPanel>
                                <h3>Data Readout</h3>
                                <BootstrapTable
                                    keyField="timestamp" // Unique identifier for each data point
                                    data={vitalSignsData}
                                    columns={[
                                    {
                                        dataField: 'timestamp',
                                        text: 'Timestamp',
                                        sort: true,
                                    },
                                    {
                                        dataField: 'bloodSugar',
                                        text: 'Blood Sugar',
                                        sort: true,
                                    },
                                    {
                                        dataField: 'bloodPressureSystolic',
                                        text: 'Systolic Blood Pressure',
                                        sort: true,
                                    },
                                    {
                                        dataField: 'bloodPressureDiastolic',
                                        text: 'Diatolic Blood Pressure',
                                        sort: true,
                                    },
                                    ]}
                                />
                            </TabPanel>
                        </Tabs>
                    </div>
                    <br/>
                    <br/>
                    {/* <div className='emergencyBtnDiv'>
                        <button className='EmergencyBtn' onClick={handleEmergencyClick} disabled={aptStatus === "Not Started"}>
                            EMERGENCY, Notify First Responders
                        </button>
                    </div> */}
                </div>
            </main>
            {showDelPopup && (
                <div className="popup">
                    <div className="content">
                        <h3>Are you sure?</h3>
                        <p>
                            Please confirm you would like to delete appointment:
                        </p>
                        <table className="aptInfoTable">
                            <tbody>
                                <tr>
                                    <td>
                                        <label htmlFor="rapid-id">RAPID ID:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.rapid_id_assigned_to}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="aptStatus">Appointment Status:</label>
                                    </td>
                                    <td>
                                        <label>{aptStatus}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="scheduled-start-time">Scheduled Start Time:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.scheduled_start}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="drug">Drug:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.drug}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="pump-status">Pump Status:</label>
                                    </td>
                                    <td>
                                        {
                                            {
                                                'Disconnected': 
                                                    <div className="disconnectedStatusIndicator">
                                                        Disconnected
                                                    </div>,
                                                'Warning':
                                                    <div className="warningStatusIndicator">
                                                        Warning
                                                    </div>,
                                                'Connected':
                                                    <div className="connectedStatusIndicator">
                                                        Connected
                                                    </div>
                                            }[pumpStatus]   
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="monitor-status">Monitor Status:</label>
                                    </td>
                                    <td>
                                        {
                                            {
                                                'Disconnected': 
                                                    <div className="disconnectedStatusIndicator">
                                                        Disconnected
                                                    </div>,
                                                'Warning':
                                                    <div className="warningStatusIndicator">
                                                        Warning
                                                    </div>,
                                                'Connected':
                                                    <div className="connectedStatusIndicator">
                                                        Connected
                                                    </div>
                                            }[monitorStatus]   
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="infusionDose">Infusion Dose:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.infusion_dose} mg</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="stepCount">Step Count:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.step_count} </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="timeIncrement">Time Increment:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.time_increment} </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button type="button" onClick={handlePopupDelete}>Delete</button>
                        <button type="button" onClick={handlePopupCancel}>Cancel</button>
                    </div>
                </div>
            )}
            {showEmergencyPopup && (
                <div className="popup">
                    <div className="content">
                        <h3>CONFIRM EMERGENCY</h3>
                        <p>
                            Please confirm that an emergency is occurring. This will send a stop response to the pump
                            and notify first responders.
                        </p>
                        <button type="button" onClick={handleEmergencyPopupConfirm}>CONFIRM EMERGENCY</button>
                        <button type="button" onClick={handleEmergencyPopupCancel}>Cancel</button>
                    </div>
                </div>
            )}
            {showStartPopup && (
                <div className="popup">
                    <div className="content">
                        <h3>Start Injection?</h3>
                        <p>
                            Please confirm that you would like to start the injection treatment with the following parameters:
                        </p>
                        <table className="aptInfoTable">
                            <tbody>
                                <tr>
                                    <td>
                                        <label htmlFor="rapid-id">RAPID ID:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.rapid_id_assigned_to}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="aptStatus">Appointment Status:</label>
                                    </td>
                                    <td>
                                        <label>{aptStatus}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="scheduled-start-time">Scheduled Start Time:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.scheduled_start}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="drug">Drug:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.drug}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="pump-status">Pump Status:</label>
                                    </td>
                                    <td>
                                        {
                                            {
                                                'Disconnected': 
                                                    <div className="disconnectedStatusIndicator">
                                                        Disconnected
                                                    </div>,
                                                'Warning':
                                                    <div className="warningStatusIndicator">
                                                        Warning
                                                    </div>,
                                                'Connected':
                                                    <div className="connectedStatusIndicator">
                                                        Connected
                                                    </div>
                                            }[pumpStatus]   
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="monitor-status">Monitor Status:</label>
                                    </td>
                                    <td>
                                        {
                                            {
                                                'Disconnected': 
                                                    <div className="disconnectedStatusIndicator">
                                                        Disconnected
                                                    </div>,
                                                'Warning':
                                                    <div className="warningStatusIndicator">
                                                        Warning
                                                    </div>,
                                                'Connected':
                                                    <div className="connectedStatusIndicator">
                                                        Connected
                                                    </div>
                                            }[monitorStatus]   
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="infusionDose">Infusion Dose:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.infusion_dose} mg</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="stepCount">Step Count:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.step_count} </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="timeIncrement">Time Increment:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.time_increment} </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button type="button" onClick={handleStartInjection}>Start Injection</button>
                        <button type="button" onClick={handleCancelInjectionStart}>Cancel</button>
                    </div>
                </div>
            )}
            {showStopPopup && (
                <div className="popup">
                    <div className="content">
                        <h3>Stop Injection?</h3>
                        <p>
                            Please confirm that you would like to stop the injection treatment before completion.
                        </p>
                        <button type="button" onClick={handleStopInjection}>Yes, I want to cancel the injection</button>
                        <button type="button" onClick={handleCancelStopInjection}>No, I do not want to cancel the injection</button>
                    </div>
                </div>
            )}
            {startCountdownActive && (
                <div className="popup">
                    <div className="content">
                        <h3>Injection Start Countdown</h3>
                        <p>
                            The injection will start in :
                        </p>
                        <Countdown 
                            className='countDown' 
                            date={Date.now() + 10000} 
                            onComplete={injectionStart}
                            renderer={
                                ({ hours, minutes, seconds, completed }) => {
                                    // Render a countdown
                                    if(!completed){
                                        return <h1>{seconds} seconds</h1>;
                                    }
                                    else{
                                        return null;
                                    }
                                }
                            }
                        />
                        <button type="button" onClick={handleCountdownCancel}>Cancel</button>
                        <h3>Appointment Details:</h3>
                        <table className="aptInfoTable">
                            <tbody>
                                <tr>
                                    <td>
                                        <label htmlFor="rapid-id">RAPID ID:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.rapid_id_assigned_to}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="aptStatus">Appointment Status:</label>
                                    </td>
                                    <td>
                                        <label>{aptStatus}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="scheduled-start-time">Scheduled Start Time:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.scheduled_start}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="drug">Drug:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.drug}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="pump-status">Pump Status:</label>
                                    </td>
                                    <td>
                                        {
                                            {
                                                'Disconnected': 
                                                    <div className="disconnectedStatusIndicator">
                                                        Disconnected
                                                    </div>,
                                                'Warning':
                                                    <div className="warningStatusIndicator">
                                                        Warning
                                                    </div>,
                                                'Connected':
                                                    <div className="connectedStatusIndicator">
                                                        Connected
                                                    </div>
                                            }[pumpStatus]   
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="monitor-status">Monitor Status:</label>
                                    </td>
                                    <td>
                                        {
                                            {
                                                'Disconnected': 
                                                    <div className="disconnectedStatusIndicator">
                                                        Disconnected
                                                    </div>,
                                                'Warning':
                                                    <div className="warningStatusIndicator">
                                                        Warning
                                                    </div>,
                                                'Connected':
                                                    <div className="connectedStatusIndicator">
                                                        Connected
                                                    </div>
                                            }[monitorStatus]   
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="infusionDose">Infusion Dose:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.infusion_dose} mg</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="stepCount">Step Count:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.step_count} </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label htmlFor="timeIncrement">Time Increment:</label>
                                    </td>
                                    <td>
                                        <label>{selectedApt.time_increment} </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AptView;