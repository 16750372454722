import React, { useState } from "react";
import "./ForgotPassword.css";
import triton_logo from './images/triton_logo.jpg';

/**
 * This component provides the rendering and logic for the forgot password page in the application. 
 * @component
 * @returns {React.HTMLAttributes}
 */
function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [showPopup, setShowPopup] = useState(false);

    /**
     * Hook to handle when the user submits the forgot password form. 
     * @param {React.ChangeEvent} event 
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        // Simulate API call to check for email
        fetch("/api/check-email", {
        method: "POST",
        body: JSON.stringify({ email }),
        })
        .then((response) => response.json())
        .then((data) => {
            setShowPopup(true);
        })
        .catch((error) => {
            console.error(error);
            setShowPopup(true);
        });
    };

    /**
     * Hook to handle when the close popup button is selected
     */
    const handleClosePopup = () => {
        setShowPopup(false);
    };

    /**
     * Provides the rendering logic for the page. 
     */
    return (
        <div className="forgot-password-page">
            <header className="header">
                <a href="/">
                <button className="home-button">
                    Back to Home
                </button>
                </a>
                <img src={triton_logo} alt="Triton Logo" />
            </header>
            <div className="content">
                <div className="forgot-form">
                    <h2 className="title">Forgot Password</h2>
                    <form className="forgot-password-form" onSubmit={handleSubmit}>
                        <label htmlFor="email">Email Address:</label>
                        <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter your registered email address"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        />
                        <button type="submit">Send Reset Instructions</button>
                    </form>
                </div>
                {showPopup && (
                    <div className="popup">
                        <div className="content">
                            <h3>Password Reset Instructions Sent</h3>
                            <p>
                                If an account with the provided email address exists, you will
                                receive an email shortly with instructions on how to reset your
                                password. Please check your inbox and spam folder.
                            </p>
                            <button onClick={handleClosePopup}>Close</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ForgotPassword;