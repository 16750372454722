/**
 * @author Aung Khant Kyaw
 */

import React, { useState } from 'react';
import './SurveyPopUp.css';

const SurveyPopup = ({ onClose, handleSurveySubmit }) => {
  const [questions, setQuestions] = useState([]);
  
  const [isRequired, setIsRequired] = useState(false); // Initial state for checkbox

  // ------------ For Add question Hover ------------ //
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  const defaultText = '+';
  const hoverText = 'Add question';
  // ------------------------------------------------//

  const handleAddQuestion = () => {
    setQuestions([...questions, { question: '', answer_type: 'text', answer_options: '', answer: null }]);
  };

  const handleCheckboxChange = (event) => {
    setIsRequired(event.target.checked);
  };

  const submitSurvey = () => {
    handleSurveySubmit(questions, isRequired);
    onClose();
  }

  const handleQuestionChange = (index, event) => {
    const newQuestions = [...questions];
    if (event.target.name === 'answer_options') {
      const newOptions = event.target.value;
      const optionString = newOptions.join(",");
      console.log("Question index: " + index);
      console.log("Option string: " + optionString);
      // Concatenate new options with existing options (if any)
      newQuestions[index].answer_options = optionString;
    } else {
      newQuestions[index][event.target.name] = event.target.value;
    }
    console.log("old questions: " , questions)
    console.log("Current questions: " , newQuestions)
    setQuestions(newQuestions);
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  return (
    <div className="modal-container">
      <div className='modal-overlay'></div>
      <div className='modal-wrapper'>
        <div className='top-wrapper'>
          <div  className='title'>Create Survey Questions</div>
          <button onMouseEnter={handleHover} onMouseLeave={handleHover} onClick={handleAddQuestion} className='add-question-btn'>
            {isHovered ? hoverText : defaultText}
          </button>
        </div>
        <div className="survey-required-wrapper">
          <label htmlFor="is-required">
            Is survey required?
          </label>
          <input
              type="checkbox"
              id="is-required"
              name="isRequired" // Add a name for form handling (optional)
              checked={isRequired}
              onChange={handleCheckboxChange}
            />
          <hr className="separator" />
        </div>
        <div className='info-wrapper'>
        {questions.map((question, index) => (
          <SurveyQuestion
            key={index}
            question={question}
            index={index}
            handleQuestionChange={handleQuestionChange}
            handleRemoveQuestion={handleRemoveQuestion}
          />
        ))}
        </div>
        <div className='btn-wrapper'>
          <button className='btn' onClick={submitSurvey}>Submit</button>
          <button className='btn' onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};
export default SurveyPopup;

const SurveyQuestion = ({ question, index, handleQuestionChange, handleRemoveQuestion }) => {
  const [answerOptions, setAnswerOptions] = useState([]);

  const handleAddOption = () => {
    setAnswerOptions([...answerOptions, ""]); // Add a new empty option
  };

  const handleOptionChange = (event, optIndex) => {
    const updatedOptions = [...answerOptions];
    updatedOptions[optIndex] = event.target.value;
    setAnswerOptions(updatedOptions);
    handleQuestionChange(index, { target: { name: 'answer_options', value: updatedOptions } });
  };

  const handleRemoveOption = (optionIndex) => {
    if (answerOptions.length > 0) { // Allow removing any option
      const updatedOptions = [...answerOptions];
      updatedOptions.splice(optionIndex, 1);
      setAnswerOptions(updatedOptions);
      handleQuestionChange(index, { target: { name: 'answer_options', value: updatedOptions } });
    }
  };  

  return (
    <div key={index} className="survey-question-wrapper">
      <div className='label-top'>
        <label htmlFor={`question-${index}`}>Question:</label>
        <input
          type="text"
          id={`question-${index}`}
          name="question"
          value={question.question}
          onChange={(e) => handleQuestionChange(index, e)}
        />
        <button type="button" onClick={() => handleRemoveQuestion(index)}>
          <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="#FFFFFF" style={{height: '25px'}}><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.12 10.47L12 12.59l-2.13-2.12-1.41 1.41L10.59 14l-2.12 2.12 1.41 1.41L12 15.41l2.12 2.12 1.41-1.41L13.41 14l2.12-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"/></svg>                    
        </button>
      </div>
      <div className='label-input'>
        <label htmlFor={`answer-type-${index}`}>Answer Type:</label>
        <select
          className='input-font'
          id={`answer-type-${index}`}
          name="answer_type"
          value={question.answer_type}
          onChange={(e) => handleQuestionChange(index, e)}
        >
          <option value="text">Text</option>
          <option value="multiple_choice">Multiple Choice</option>
          <option value="checkbox">Checkbox</option>
          <option value="dropdown">Dropdown</option>
        </select>
        {question.answer_type === 'multiple_choice' || question.answer_type === 'dropdown' || question.answer_type === 'checkbox' ? (
          <button type="button" onClick={handleAddOption}>Add Option</button>
        ) : null}
      </div>
      {question.answer_type === 'multiple_choice' || question.answer_type === 'dropdown' || question.answer_type === 'checkbox' ? (
        <div className="answer-options">
          {answerOptions.map((option, optIndex) => (
            <div key={optIndex} className="answer-option">
              <input
                type="text"
                placeholder={`Option ${optIndex + 1}`}
                value={option}
                onChange={(event) => handleOptionChange(event, optIndex)}
              />
              <div className='answer-option-button' type="button" onClick={() => handleRemoveOption(optIndex)} disabled={answerOptions.length < 1}>
              x 
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <hr className="separator" />
    </div>
  );
};