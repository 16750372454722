import React, { useState } from "react";
import './Signup.css';
import triton_logo from './images/triton_logo.jpg';
import { useNavigate } from "react-router-dom";
import ohsu_logo from './images/ohsu.jpg';
import columbia_logo from './images/columbia.png';
import axios from "axios";
import bcryptjs from 'bcryptjs';

/**
 * Component to provide the signup styling and logic
 * @component
 */
function Signup() {
  const [institution, setInstitution] = useState("None");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  /**
   * Hook to handle submission of signup credentials. 
   * @param {*} event The submit button select event
   * @returns {null}
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    // Implement your sign in logic here
    console.log("Email:", email);
    console.log("Password:", password);
    console.log("Institution:", institution);
    try{
      const salt = bcryptjs.genSaltSync(10);
      const hashedPassword = bcryptjs.hashSync(password, salt);

      const signupEndpoint = String(process.env.REACT_APP_API_URI) + "/signup";
      console.log("signupURI: ", signupEndpoint);
      axios.post(signupEndpoint, {
        organization: institution,
        email: email,
        password: hashedPassword,
        salt: salt
      }).then((response) => {
        console.log(response);
        setShowPopup(true);
      }, (error) => {
        console.log(error);
        setShowErrorPopup(true);
      });

    } catch(Error) {
      console.log(Error);
      return;
    }

  };

  /**
   * Hook to handle closing any popup for the page
   */
  const handleClosePopup = () => {
    setShowPopup(false);
    setShowErrorPopup(false);
    navigate("/", {
      state: {
        email: email
      }
    })
};

  /**
   * Hook to handle changes in the dropdown selection
   * @param {*} event The dropdown change event
   */
  const handleDropdownChange = (event) => {
    event.preventDefault();
    setInstitution(event.target.value);
  }

  /**
   * Retrieves the correct organization logo based on the selction of the dropdown
   * @returns The logo image in HTML
   */
  const getInstitutionLogo = () => {
    if(institution !==null){
      switch(institution) {
        case 'Columbia University':
          return <img src={columbia_logo} alt="Columbia University Logo" />;
        case 'Oregon Health and Science University':
          return <img src={ohsu_logo} alt="Oregon Health and Science University Logo" />;
        default:
          return;
      }
    }
  }

  /**
   * Rendering function
   */
  return (
    <div className="sign-up-page">
      <header className="header">
        <a href="/">
          <button className="home-button">
            Back
          </button>
        </a>
        <span class="tab"></span>
        <div className="logo2">
          <img src={triton_logo} alt="Triton Logo" />
        </div>
      </header>
      
      <div className="signUpContainer">
        <h2>Sign Up</h2>
        <div className="formContents">
          <form className="sign-up-form" onSubmit={handleSubmit}>
            <label htmlFor="institution">Select your Institution:</label>
            <select value={institution} onChange={handleDropdownChange}>
              <option name="None">None</option>
              <option name="Columbia University">Columbia University</option>
              <option name="Oregon Health and Science University">Oregon Health and Science University</option>
            </select>
            {institution !=="None" &&<label 
              htmlFor="email"
              id="emailLabel"
              >Set an Email Address:
            </label>}
            <input 
              type="email" 
              id="email" 
              name="email" 
              placeholder="Enter your email address" 
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              hidden={institution==="None"}
              disabled={institution==="None"}
            />
            {institution !=="None" && <label 
              htmlFor="password"
              id="passwordLabel"
              >Set a Password:
            </label>}
            <input 
              type="password" 
              id="password" 
              name="password"
              placeholder="Enter your password" 
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              hidden={institution==="None"}
              disabled={institution==="None"}
            />
            <br/>
            <button 
              type="submit"
              hidden={institution==="None"}
              disabled={institution==="None"}
            >
              Sign Up
            </button>
          </form>
          {institution? (
            <div className="institutionLogo">
              {
                getInstitutionLogo()
              }
            </div>
            ):(
                <null></null>
            )}
        </div>
      </div>
      {showPopup && (
        <div className="popup">
            <div className="content">
                <h3>Account Created</h3>
                <p>
                    Your account has been successfully created! Please login using the credentials you set.  
                </p>
                <button onClick={handleClosePopup}>Close</button>
            </div>
        </div>
      )}
      {showErrorPopup && (
        <div className="popup">
            <div className="content">
                <h3>No Account Created</h3>
                <p>
                    Thank you for requesting an account. Unfortunately, we were unable to verify a valid license for the information you provided. 
                    Please make sure that your institution's point of contact has registered your email with us. 
                </p>
                <button onClick={handleClosePopup}>Close</button>
            </div>
        </div>
      )}
    </div>
  );
}

export default Signup;