import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import Main from "./components/Main";
import Home from "./components/Home";
import AddNewID from "./components/AddNewID";
import AptView from "./components/AptView";
import ForgotPassword from './components/ForgotPassword';
import PatientView from './components/PatientView';

/**
 * Provides the HTML rendering of the React application and sets the routes to each component. 
 * @returns The HTML rendering of the entire React appliction
 */
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/main" element={<Main />} />
        <Route path="/addnewid" element={<AddNewID />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path='/aptview' element={<AptView />} />
        <Route path='/patientview' element={<PatientView />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
