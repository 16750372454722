import React, { useState, useEffect } from "react";
import "./PatientView.css";
import triton_logo from "./images/triton_logo.jpg";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import {useNavigate, useLocation } from "react-router-dom";
import Slider from 'react-slider';
import axios from "axios";

import SurveyPopup from './SurveyPopUp';

/**
 * Component to provide the rendering and logic for the patient or RAPID ID view 
 * @component
 * @returns {React.HTMLAttributes}
 */
function PatientView() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    console.log("location:", location);

    const [showPopup, setShowPopup] = useState(false);

    const email = location.state.email;
    const rapid_id = location.state.rapid_id;
    console.log(rapid_id);
    const user_id = location.state.user_id;

    const [drug, setDrug] = useState("Rotigotine");
    const [infusionDose, setInfusionDose] = useState(0);
    const [schedule, setSchedule] = useState([]);
    const [showAddAptPopup, setShowAddAptPopup] = useState(false);
    const [appointments, setAppointments] = useState([]);

    // Survery Pop Up 
    const [showSurveyPopup, setShowSurveyPopup] = useState(false);
    const [survey,  setSurvey] = useState([]);
    const surveyAdded = survey.length > 0;
    const [required, setRequired]  =  useState(false);

    /**
     * @function
     * This function is called whenever the state is updated to reflect current state variable data. We use it to load (and reload) the appointments associated with the user. 
     */
    useEffect(() => {
        console.log("in useeffect func");
        if(isLoading){
            const getAptsPerRapidIDEndpoint = String(process.env.REACT_APP_API_URI) + "/appointments/" + rapid_id;
            axios.get(getAptsPerRapidIDEndpoint)
            .then((response) => {
                console.log(response);
                setAppointments(response.data);
                console.log(appointments);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
    }});

    /**
     * This function naviagates the the correct appointment view when the user selects one of the appointment rows from the table
     * @param {React.HTMLAttributes} appointment 
     * @returns {null}
     */
    const handleAptRowClick = (appointment) => () => {
    // Implement your logic for handling appointment row click here
        console.log(`Appointment clicked: ${appointment.rapid_id_assigned_to} at ${appointment.scheduled_start}`);
        navigate("/aptview", {
            state: {
                email: email,
                user_id: user_id,
                selectedApt: appointment
            }
        })
    };

    /**
     * This function updates the state value when the dosage rate slider is changed
     * @param {Float} newValue 
     */
    const handleInfusionDoseSliderChange = (newValue) => {
        setInfusionDose(newValue);
    }
    
    /**
     * This function calls the appointment row click even handler when an appointment row is selected via keyboard navigation
     * @param {React.ChangeEvent} event 
     * @param {React.HTMLAttributes} appointment 
     */
    const handleAptRowKeyDown = (event, appointment) => {
        if (event.key === "Enter") {
        handleAptRowClick(appointment)();
        }
    };

    /**
     * Hook to handle when the button to add a new appointment is selected
     */
    const handleAddAptBtnClick = () => {
        // Implement your logic for adding a new Rapid ID here
        console.log("Add Apt button clicked");
        console.log("schedule:", schedule)
        setShowAddAptPopup(true);
    };

    /**
     * Hook to handle hover enter events on the table rows
     * @param {React.ChangeEvent} e 
     */
    const handleTableRowMouseEnter = (e) => {
        console.log("e:",e);
        e.target.parentElement.style.backgroundColor = "#f5f5f5";
    };

    /**
     * Hook to handle hover leave events on table rows
     * @param {React.ChangeEvent} e 
     */
    const handleTableRowMouseLeave = (e) => {
        e.target.parentElement.style.backgroundColor = "white";
    };

    /**
     * Hook to trigger the deletion popup when the delete appointment is selected
     */
    const handleDelClick = () => {
        setShowPopup(true);
    }

    /**
     * Hook to return to main menu screen when the user is done editing the patient appointments
     * @param {React.ChangeEvent} event 
     */
    const handleSubmit = (event) => {
        event.preventDefault();

        console.log("Rapid ID:", rapid_id);
        console.log("Appointments:", appointments);

        navigate("/main", {
        state: {
            email: email,
            user_id: user_id,
        },
        });
    };

    /**
     * Hook to close the popup when the close button is selected
     */
    const handlePopupCancel = () => {
        setShowPopup(false);
    };

    /**
     * Hook to cancel adding new appointments when the popup is cancelled
     */
    const handleAddAptPopupCancel = () => {
        setShowAddAptPopup(false);
    }

    /**
     * Hook to call API to a new appointment(s) for the current RAPID ID when the user accepts the submit popup 
     */
    const handleAddAptPopupClick = () => {
        console.log("Rapid ID:", rapid_id);
        console.log("Entered Drug:", drug);
        console.log("Entered Infusion Dose :", infusionDose);
        console.log("Entered Scheduled Date:", schedule[0].toString());
        console.log("Email:", email);
        console.log("RequiredSurvey", survey);
        console.log("Survey", survey);
        setShowAddAptPopup(false);

        const addAptEndpoint = String(process.env.REACT_APP_API_URI) + "/appointment";
        for (var date in schedule){
            axios.post(addAptEndpoint, {
                rapid_id_assigned_to: rapid_id,
                user_assigned_to: user_id,
                drug: drug,
                infusion_dose: infusionDose,
                step_count:0,
                time_increment:0,
                scheduled_start: schedule[date].toString(),
                infusion_start_time: "N/A",
                amt_administered: 0,
                required_survey: required,
                surveys: survey
            }).then((response) => {
                console.log(response);
                setDrug("Rotigotine");
                setInfusionDose("0");
                setSchedule([]);
                setIsLoading(true);
            }, (error) => {
                console.log(error);
                setDrug("Rotigotine");
                setInfusionDose("0");
                setSchedule([]);
            });
        }
        setDrug("Rotigotine");
        setInfusionDose("0");
        setSchedule([]);
        setIsLoading(true);
    }

    /**
     * Hook to handle changes to the drug dropdown
     * @param {React.ChangeEvent} event 
     */
    const handleDropdownChange = (event) => {
        event.preventDefault();
        setDrug(event.target.value);
    }

    /**
     * Hook to handle deletion of a RAPID ID when the user confirms deletion in the deletion popup. Also deletes all appointments associated with the user. 
     */
    const handlePopupDelete = () => {
        console.log("deleting rapid_id:", rapid_id);
        const deleteRapidIDEndpoint = String(process.env.REACT_APP_API_URI) + "/rapid_id/" + rapid_id;
        const deleteAptEndpointBase = String(process.env.REACT_APP_API_URI) + "/appointment/";

        console.log(appointments);

        for(var appointment in appointments) {
            console.log(appointments[appointment]);
            var deleteAptEndpoint = deleteAptEndpointBase + appointments[appointment]._id.$oid;
            console.log("delete apt endpoint: ", deleteAptEndpoint);
            axios.delete(deleteAptEndpoint).then((response) => {
                console.log(response);
            }, (error) => {
                console.log(error);
            });
        }
        axios.delete(deleteRapidIDEndpoint).then((response) => {
            console.log(response);
            navigate("/main", {
                state:{
                    email: email,
                    user_id: user_id
                }
            });
        }, (error) => {
            console.log(error);
        });
        setShowPopup(false);
    };

    const handleAddSurvey = () => {
        setShowSurveyPopup(!showSurveyPopup);
    };

    const handleSurveyQuestion = (questions,  isRequired) => {
        setSurvey(questions);
        setRequired(isRequired);
        // setShowSurveyPopup(false);
    }

    /**
     * Rendering logic for the patient view
     */
    if (isLoading || appointments === null) {
        console.log("loading...");
        return <p>Loading...</p>;
    }
    else {
        console.log("done loading");
        return (
            <div className="patient-view-page">
            <header className="header3">
                <button onClick={() => 
                    navigate(-1)
                }>
                    Back
                </button>
                <div className="log2">
                <img src={triton_logo} alt="Triton Logo" />
                </div>
            </header>
            <br/>
            <div className="titleSpace">
                <h2>RAPID ID Info</h2>
                <button onClick={() => 
                    navigate("/addnewid", {
                        state:{
                            email: email,
                        }
                    })
                }>
                    Add New RAPID ID
                </button>
            </div>
            <form className="patient-view-form" onSubmit={handleSubmit}>
                <div className="rapid-section">
                    <label htmlFor="rapid_id">RAPID ID: {rapid_id}</label>
                    <button type="button" onClick={handleDelClick}>
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"  fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14.12 10.47L12 12.59l-2.13-2.12-1.41 1.41L10.59 14l-2.12 2.12 1.41 1.41L12 15.41l2.12 2.12 1.41-1.41L13.41 14l2.12-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"/></svg>                    
                    </button>
                </div>
                <br/>
                <div className="aptContainer">
                    {rapid_id ? (
                    <form className="add-new-apt-form" onSubmit={handleSubmit}>
                        <h2>Add a new appointment</h2>
                        <label htmlFor="drug">Drug:</label>
                        <select value={drug} onChange={handleDropdownChange}>
                            <option name="Rotigotine">Rotigotine</option>
                            <option name="Selegiline">Selegiline</option>
                        </select>

                        <label htmlFor="infusionDose">Infusion Dose (mg):</label>
                        <div className="infusionDoseDiv">
                            <input
                            type="number"
                            id="infusionDose"
                            name="infusionDose"
                            placeholder="Enter the infusion dose to be administered in mg"
                            value={infusionDose}
                            onChange={(event) => setInfusionDose(event.target.value)}
                            disabled={!rapid_id}
                            />
                            <Slider 
                                value={infusionDose}
                                onChange={handleInfusionDoseSliderChange}
                                min={0}
                                max={5}
                                step={0.25} // Adjust for desired precision
                                valueLabelDisplay="auto"
                                className="customSlider"
                                trackClassName="customSlider-track"
                                thumbClassName="customSlider-thumb"
                                markClassName="customSlider-mark"
                                disabled={showAddAptPopup === true || showPopup === true}
                                renderThumb={(props, state) => (!showAddAptPopup && !showPopup) && <div {...props}><br/>{state.valueNow}</div>}
                            />
                        </div>
                        <label htmlFor="schedule">Appointment Schedule:</label>
                        <DatePicker
                            className="customDatePicker"
                            value={schedule}
                            onChange={setSchedule}
                            disabled={!rapid_id}
                            format="MM/DD/YYYY HH:mm:ss"
                            containerStyle={{
                                width: "100%",
                                height: "10%",
                            }}
                            plugins={[
                                <TimePicker position="bottom" />,
                                <DatePanel markFocused />
                            ]}
                        /> 
                        <br/><br/>
                        <button 
                            className="add-apt-btn"
                            type="button"
                            onClick={handleAddSurvey}
                            disabled={surveyAdded}
                        >
                            {surveyAdded ? 'Survey Added' : 'Add Survey'}
                        </button>

                        <br/><br/>
                        <button 
                            className="add-apt-btn"
                            type="button"
                            onClick={handleAddAptBtnClick}
                            disabled={!rapid_id || !drug || infusionDose <= 0 || schedule.length === 0}
                        >
                            Add New Appointment 
                        </button>
                    </form>
                    ) : (
                        <div>
                            <br/><br/>
                            <h2>Enter a Rapid ID to add appointments.</h2>
                        </div>
                    )}

                    <div className="tables-container">
                    <h2>Scheduled Appointments</h2>
                    <div className="appointments-container">
                        {appointments.length === 0 ? (
                            <p>No upcoming appointments</p>
                        ) : (
                            rapid_id ? (
                                <table className="appointments-table" >
                                <thead>
                                    <tr>
                                    <th>Appointment ID</th>
                                    <th>Scheduled Start</th>
                                    <th>Drug</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {appointments.map((appointment) => (
                                    <tr
                                        key={appointment._id.$oid}
                                        tabIndex="0"
                                        role="button"
                                        onClick={handleAptRowClick(appointment)}
                                        onKeyDown={(event) => handleAptRowKeyDown(event, appointment)}
                                        onMouseEnter={handleTableRowMouseEnter}
                                        onMouseLeave={handleTableRowMouseLeave}
                                    >
                                        <td>{appointment._id.$oid}</td>
                                        <td>{appointment.scheduled_start}</td>
                                        <td>{appointment.drug}</td>
                                    </tr>
                                    ))}
                                </tbody>
                                </table>
                            ) : (
                                <p>Enter a Rapid ID to add appointments.</p>
                            )

                        )}
                        </div>
                    </div>
                </div>

                <br/>
                <button type="submit" disabled={!rapid_id}>Done</button>
            </form>
            {showPopup && (
                <div className="popup">
                    <div className="content">
                        <h3>Are you sure?</h3>
                        <p>
                            Please confirm you would like to delete RAPID-ID User: {rapid_id}.
                        </p>
                        <button type="button" onClick={handlePopupDelete}>Delete</button>
                        <button type="button" onClick={handlePopupCancel}>Cancel</button>
                    </div>
                </div>
            )}

            {showAddAptPopup && (
                <div className="popup">
                <div className="content">
                    <h3>Add appointment?</h3>
                    <p>
                        Please confirm you would like to add a new appointment for RAPID User: {rapid_id} 
                        with the following details:
                    </p>
                    <p>
                        Drug: {drug}
                    </p>
                    <p>
                        Infusion Dose: {infusionDose} mg
                    </p>
                    <p>
                        Appointment Schedule: {
                                schedule.map(apt => (
                                    <p style={{fontSize: "25px"}}>{apt.toString()}</p>
                                )
                            )} 
                    </p>
                    <button type="button" onClick={handleAddAptPopupClick}>Add</button>
                    <button type="button" onClick={handleAddAptPopupCancel}>Cancel</button>
                </div>
            </div>
            )}

            {showSurveyPopup && <SurveyPopup onClose={() => setShowSurveyPopup(false)} handleSurveySubmit={(surveyQuestion, required) => handleSurveyQuestion(surveyQuestion, required)} />}
            </div>
        );
    }
}

export default PatientView;